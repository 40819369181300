.header {
  text-align: center;
  margin-bottom: 20px;
}

.logo {
  max-width: 400px;
  height: auto;
  margin-bottom: 10px;
}

h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

/* Container for the form */
.registration-form {
  background-color: #ffffff;
  padding: 2rem;
  max-width: 600px;
  margin: 2rem auto;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #333;
  box-sizing: border-box;
  overflow: hidden;
}

/* Form title */
.registration-form h2 {
  font-size: 1.5rem; /* Use rem for scalability */
  margin-bottom: 1.5rem;
  font-weight: 600;
  color: #1e1e1e;
  text-align: center;
}

/* Label for input fields */
.registration-form label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #555;
}

/* Input fields */
.registration-form input[type="text"],
.registration-form input[type="email"],
.registration-form input[type="tel"] {
  width: calc(100% - 16px);
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: #f9f9f9;
  font-size: 1rem;
  color: #333;
  box-sizing: border-box;
}

.registration-form input[type="text"]:focus,
.registration-form input[type="email"]:focus,
.registration-form input[type="tel"]:focus {
  outline: none;
  border-color: #000000;
  background-color: #ffffff;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
}

/* Button styles */
.registration-form button {
  display: inline-block;
  width: 100%;
  padding: 0.75rem;
  margin-top: 1rem;
  background-color: #000000;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 1rem;
  transition: background-color 0.3s ease;
}

.registration-form button:hover {
  background-color: #ffffff;
  color: #000000;
  border: 2px solid #000000;
  border-radius: 4px;
}

.registration-form button:focus {
  outline: 2px solid #2563eb;
  background-color: #ffffff;
}

/* Error message */
.error-message {
  color: #e3342f;
  font-size: 0.875rem;
  margin-top: -0.5rem;
}

/* Stepper container */
.stepper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 2rem;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

/* Line connecting the steps */
.stepper::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ccc;
  z-index: 0;
  transform: translateY(-50%);
}

/* Step boxes */
.step {
  position: relative;
  z-index: 1;
  background-color: #f9f9f9;
  color: transparent; /* Remove text */
  width: 50px;
  height: 50px;
  border-radius: 4px; /* Make steps square */
  border: 2px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  flex-shrink: 0;
}

/* Active step style */
.step.active {
  background-color: #000000;
  border-color: #000000;
}
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height of the view */
  width: 100vw;  /* Full width of the view */
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999; /* Ensure it is above everything */
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top: 5px solid #ffe404;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Keyframes for the spinner animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Media query for smaller screens */
@media (max-width: 325px) {
  .registration-form {
      padding: 1rem;
      margin: 1rem;
  }

  .stepper {
      justify-content: space-between;
  }

  .step {
      width: 50px;
      height: 50px; /* Keep steps the same size on mobile */
  }

  .stepper::before {
      display: none; /* Hide connecting line on screens below 300px */
  }
}
